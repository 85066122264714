import {weblogin} from '../../api/Login'


export const state = {
    // aaa:66
}

export const actions = {
    WebLogin(_,data) {
        return weblogin(data)
    }
}
export const mutations = {

}