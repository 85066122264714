<template>
    <div></div>
  </template>
  <script>
   
  export default {
    data() {
      return {
        timer: null,
        noOperateTime: 1000 * 60 * window.globalUrl.login_time // 用户不操作的时间
      }
    },
    created() {},
    mounted() {
      /*
        第一步：
          组件初始化加载就绑定监听点击事件，注意：addEventListener的第三个参数，这里要加上。
          因为第三个参数决定了是冒泡还是捕获（false冒泡默认，true捕获），因为绑定监听点击事件，我们是在最
          顶层的DOM位置进行捕获点击事件，所以第三个参数true，要加上的，这样的话，内层的任意地方的点击事件
          我们就都能监听到了，然后存储一下点击的时间
      */
      window.addEventListener(
        'click',
        () => {
          // 为了方便，我们把点击事件的时间直接存到sessionStorage中去，这样方便获取比较
          sessionStorage.setItem('lastClickTime', new Date().getTime())
        },
        true
      )
      this.isTimeOut()
    },
    beforeDestroy() {
      // 最后一步，离开页面的时候，清除一下定时器，也解绑点击事件
      clearInterval(this.timer)
      window.removeEventListener('click', () => {}, true)
    },
    methods: {
      isTimeOut() {
        clearInterval(this.timer)
        console.log('调用了一次方法')
        this.timer = setInterval(() => {
          let isLoginHref = window.location.pathname
          if (!isLoginHref.includes('/login')) {
            let lastClickTime = Number(sessionStorage.getItem('lastClickTime'))
            let nowTime = new Date().getTime()
            if (nowTime - lastClickTime > this.noOperateTime) {
              this.$message.error(`您已经${window.globalUrl.login_time}分钟没有操作运营管理平台,请重新登录使用。`)

              // 退出逻辑
              localStorage.removeItem('brd-aLL')

              this.$router.push({name:'login'})
            }
          }
        }, 1000 * 60 * 30)
      }
    }
  }
  </script>