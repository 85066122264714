<template>
  <div id="app">

    <router-view/>

    <ltnoclick></ltnoclick>
  </div>
</template>


<!-- <script src="./app1"></script> -->
<script>
  import ltnoclick from './components/lastclick.vue';
export default {
    components: {
      ltnoclick
    },
    data() {
      return {
        quanpingstatus:false
      }
    },
    created(){
console.log("oooooooooooooooooooooo")
    },
    mounted() {
      // window.onload = function() {
      //   console.log(111222333,'页面加载完成',this.$route)

      // }
      if(window.location.href.indexOf('login') == '-1') {
        console.log(window.screen.width == 1920,'不该不生效把',window.location,window.location.href,window.location.hash)
        // if()
        let winFlag = window.innerHeight === window.screen.height

        if(window.location.hash == '#/') {
          return
        }
        if(window.screen.width == 1920) {

          if(winFlag) {

            document.body.style.zoom = "1"
          }else {
            document.body.style.zoom = "0.89"

          }

        }else {
          if(winFlag) {

            document.body.style.zoom = "0.85"
          }else {
            document.body.style.zoom = "0.74"

          }
        // }else if(window.screen.width == 2560){
        }   
      }
      console.log(window.location.href.indexOf('1111'),'123页面加载完成')
      window.addEventListener("keydown", this.handleKeyDown,true); // 监听按键事件，‌第三个参数为true表示在捕获阶段处理事件

      // screenfull.on('change', handleFullscreenEvent);
      // document.addEventListener('fullscreenchange', this.fullscreenChangeHandler);
      console.log(111,'绑定了吗')
      // window.addEventListener('resize', this.fullscreenChangeHandler);
      console.log(222,'绑定了吗')

    },
    methods:{
      gotologon() {
        localStorage.removeItem('brd-aLL')
        this.$router.push({name:'login'})
        console.log(1111,'gotologin')
      },
      handleKeyDown(event) {
        console.log(event,'f11的再次按下呢')
        if(event.keyCode == 122) {
          // event.returnValue = false;
          console.log(event,'按下了f11')
          // document.body.style.zoom = "100%"
          // this.quanpingstatus = true

          // document.body.style.zoom = "100%"


          this.quanpingstatus = true

        }
      },
      fullscreenChangeHandler() {
        
        let winFlag = window.innerHeight === window.screen.height
        console.log(winFlag,'winFlag全屏有了效果')
        // if(window.location.href.indexOf('login') > 0 ) {
        //   return
        // }       
        if(winFlag) {
          console.log('全屏效果111')
          document.body.style.zoom = "1"
        }else{
          if(window.screen.width == 1920) {

            document.body.style.zoom = "0.89"
          }else if(window.screen.width == 2560){
              document.body.style.zoom = "0.74"
          }      
          this.quanpingstatus=false  
          

        }
        // if(this.quanpingstatus) {
        //   this.quanpingstatus = false

        //   if(window.screen.width == 1920) {

        //     document.body.style.zoom = "90%"
        //   }else{
        //       document.body.style.zoom = "88%"

        //   }
        // }else{
        //   document.body.style.zoom = "100%"

        // }
        console.log('这里是屏幕事件按下了f11',this.quanpingstatus)
      }
    }

  }

</script>


<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  // min-width: 1920px;

  background-color: #000;
  overflow: auto;
}

body {
  // min-height: 1066px;
  min-width: 1915px;
}

@media screen and (min-width:1850px) and (max-width:2020px) {
  body{
    min-width: 1915px
  }
    }
@media screen and  (max-width:1800px) {
  body{
    min-width: 1702.67px
  }
    }
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  color: #fff;
  width: 100%;
  height: 100%;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
    .v-modal {
        // background-color: #666 !important;
        background-color: rgb(204,204,204) !important;
       
    }

      /* 整个滚动条 */
    ::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 5px;
    /* 对应横向滚动条的宽度 */
    height: 10px  ;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: #06DFFF !important;
    border-radius: 32px !important;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: #32466C !important;
    border-radius: 32px !important;
}

table{
  border-collapse: collapse !important;
}

.el-message-box {
  .el-button--primary {
    background-color: #108EE9;
    border-color: #108EE9;
  }
  .btn-classqueren {
    background-color: #108EE9 !important;
    border-color: #108EE9  !important;
  }
}

.el-pager {
  li{
    background-color: #000 !important;
  }
}
.el-submenu__title {
  i{
    color: #fff !important;
  }
}




.picker-popper-lt {
      // background-color: red;
      .popper__arrow {
        display: none !important;
      }
    }
</style>
