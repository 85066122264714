import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/index.vue'),
  },
  
  {
    path: '/weixingmap',
    name: 'weixingmap',
    component: () => import(/* webpackChunkName: "about" */ '../views/weixingmap/index.vue'),
  },


  {
    path: '/',
    name: 'home',
    component:() => import('../views/keshikantai/index.vue'),
    children:[
      {
        path:'keshi',
        name:'keshi',
        component:() => import('../views/keshi/index.vue'),
      },
      {
        path:'zutai',
        name:'zutai',
        component:() => import('../views/zutai/index.vue'),
        children:[
          // 璜泾站  一号机组
          {
            path:'huangjingone',
            name:'huangjingone',
            component:() => import('../views/huangjingone/index.vue'),
          },
          // 璜泾站  二号机组
          {
            path:'huangjingtwo',
            name:'huangjingtwo',
            component:() => import('../views/huangjingtwo/index.vue'),
          },
          //时思站
          {
            path:'shisizhan',
            name:'shisizhan',
            component:() => import('../views/shisizhan/index.vue'),
          },
          //浏家港站
          {
            path:'liujiagang',
            name:'liujiagang',
            component:() => import('../views/liujiagang/index.vue'),
          },
          //陆渡站
          {
            path:'ludu',
            name:'ludu',
            component:() => import('../views/ludu/index.vue'),
          },
          //横沥路站
          {
            path:'henglilu',
            name:'henglilu',
            component:() => import('../views/henglilu/index.vue'),
          },
          //万金路站
          {
            path:'wanjin',
            name:'wanjin',
            component:() => import('../views/wanjin/index.vue'),
          },
          //200吨站点
          {
            path:'twohundred',
            name:'twohundred',
            component:() => import('../views/twohundred/index.vue'),
          },
          //50吨站点
          {
            path:'jizu/:id',
            name:'jizu',
            component:() => import('../views/jizu/index.vue'),
          },
          //致和塘河
          {
            path:'zhihetang',
            name:'zhihetang',
            component:() => import('../views/zhihetang/index.vue'),
          },
          //鸡鸣塘河
          {
            path:'jimingtang',
            name:'jimingtang',
            component:() => import('../views/jimingtang/index.vue'),
          },
          //新溇浦河
          {
            path:'projectsite/:id',
            name:'projectsite',
            component:() => import('../views/projectsite/index.vue'),
          },
          //西长泾河
          {
            path:'site/:id',
            name:'site',
            component:() => import('../views/site/index.vue'),
          },
          //娄东片区
          {
            path:'loudong',
            name:'loudong',
            component:() => import('../views/loudong/index.vue'),
          },
          //太仓港片区
          {
            path:'jiankong',
            name:'jiankong',
            component:() => import('../views/jiankong/index.vue'),
          },
        ]
      },

      {
        path:'yingyongguanli',
        name:'yingyongguanli',
        component:() => import('../views/yingyongguanli/index.vue'),
        children:[
          // 污水处理项目
          {
            path:'wushuichuli',
            name:'wushuichuli',
            component:() => import('../views/wushuichuli/index.vue'),
          },
          // 河道水环境项目
          {
            path:'hedaoshui',
            name:'hedaoshui',
            component:() => import('../views/hedaoshui/index.vue'),
          },
          // 提升泵站项目
          {
            path:'bengzhan',
            name:'bengzhan',
            component:() => import('../views/bengzhan/index.vue'),

          },
          // 垃圾渗滤项目
          {
            path:'lajishenlv',
            name:'lajishenlv',
            component:() => import('../views/lajishenlv/index.vue'),

          },
          // 人员档案
          {
            path:'renyuandangan',
            name:'renyuandangan',
            component:() => import('../views/renyuandangan/index.vue'),

          },
          // 考勤
          {
            path:'kaoqin',
            name:'kaoqin',
            component:() => import('../views/kaoqin/index.vue'),

          },
          // PAC
          {
            path:'PAC',
            name:'PAC',
            component:() => import('../views/PAC/index.vue'),

          },
          // PAM
          {
            path:'PAM',
            name:'PAM',
            component:() => import('../views/PAM/index.vue'),

          },
          // 碳源药剂
          {
            path:'tanyuan',
            name:'tanyuan',
            component:() => import('../views/tanyuan/index.vue'),

          },
          // 其它物料
          {
            path:'qita',
            name:'qita',
            component:() => import('../views/qita/index.vue'),

          },
          // 任务发布
          {
            path:'taskfabu',
            name:'taskfabu',
            component:() => import('../views/taskfabu/index.vue'),

          },
          // 任务审核
          {
            path:'taskshenhe',
            name:'taskshenhe',
            component:() => import('../views/taskshenhe/index.vue'),

          },
          // 水量报表
          {
            path:'shuiliangbaobiao',
            name:'shuiliangbaobiao',
            component:() => import('../views/shuiliangbaobiao/index.vue'),

          },
          // 水质报表
          {
            path:'shuizhibaobiao',
            name:'shuizhibaobiao',
            component:() => import('../views/shuizhibaobiao/index.vue'),

          },
          // 用电报表
          {
            path:'yongdianbaobiao',
            name:'yongdianbaobiao',
            component:() => import('../views/yongdianbaobiao/index.vue'),

          },
          // 物流报表
          {
            path:'wuliubaobiao',
            name:'wuliubaobiao',
            component:() => import('../views/wuliubaobiao/index.vue'),

          },
          // 任务报表
          {
            path:'renwubaobiao',
            name:'renwubaobiao',
            component:() => import('../views/renwubaobiao/index.vue'),

          },
          // 报警信息
          {
            path:'baojingxinxi',
            name:'baojingxinxi',
            component:() => import('../views/baojingxinxi/index.vue'),

          },
          // 用户管理
          {
            path:'yonghu',
            name:'yonghu',
            component:() => import('../views/yonghu/index.vue'),

          },
          // 角色管理
          {
            path:'jueseshezhi',
            name:'jueseshezhi',
            component:() => import('../views/jueseshezhi/index.vue'),

          },
          // 任务管理
          {
            path:'renwushezhi',
            name:'renwushezhi',
            component:() => import('../views/renwushezhi/index.vue'),

          },
          

        ]
      }
    ]
  }
]

let originPush =  VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject){
  if (resolve && reject) {    //如果传了回调函数，直接使用
      originPush.call(this, location, resolve, reject);
  }else {                     //如果没有传回调函数，手动添加
      originPush.call(this, location, ()=>{}, ()=>{}); 
  }
}


const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

// router.afterEach(() => {
//   window.scrollTo(0,0)
// })

const whiteList = ['/login']
router.beforeEach((to, form, next) => {
  // to 即将要进入路由的对象
  // from 要离开的路由对象
  // 调用 next() 方法，进入下一个路由
  let token = JSON.parse(localStorage.getItem('brd-aLL')) 
  if(token) {
    token = token.token ? token.token : ''

  }else {
    token = ''
  }

  // try {
  //   let userInfo = JSON.parse(sessionStorage.userInfo) // 将 sessionStorage 字符串解析为对象
  //   // 防止刷新页面 vuex 中的用户信息清除，将 sessionStorage 中的用户信息再存储到 vuex 中
  //   store.dispatch('setUserInfo', userInfo)
  //   token = userInfo.token
  // } catch (error) {
  //   token = ''
  // }
  if (token) {
    if(to.path === '/') {
      // next({
      //   path: '/keshi'
      // })
      next(`/login?redirect=${to.path}`)
      console.log(111,'路由重定向到登录页')
      // return
    }else {
      next()
    }
    // else {

    //   // 如果有 token，登录成功
    //   if (to.path === '/login') {
    //     // 访问登录页，就重定向到首页
    //     // window.location.href = "http://192.168.5.146:8083/index.html"
    //     next({
    //       path: '/keshi'
    //     })
    //     console.log(2222,'路由重定向到登录页')
  
    //     // window.location.href = "http://192.168.5.146:8083/index.html"
    //     // next()
    //   } else {
    //     // 访问其他页面，进入下一个路由
    //     next()
    //   }
    // }
  } else {
    // 没有 token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在不需要重定向的页面直接登录
      next()
    } else {
      // 其他没有访问权限的页面被重定向到登录页
      next(`/login?redirect=${to.path}`)


      // window.location.href = "http://192.168.5.146:8083/index.html"
      // next()
    }
  }
})


export default router
